import {
  installEcomPlatform,
  addComponents,
  EcomComponent,
} from '@wix/ecom-platform-sdk';
import { EditorSDK } from '@wix/platform-editor-sdk';
import type { FlowAPI, IHttpClient } from '@wix/yoshi-flow-editor';
import { queryForms } from '@wix/ambassador-forms-v4-form/http';
import { ValidationFormat } from '@wix/ambassador-forms-v4-form/types';
import { NAMESPACE } from '../constants/namespace';

export const ECOM_DEF_ID = '1380b703-ce81-ff05-f115-39571d94dfcd';

async function isEcomPageInstalled(
  pageId: EcomComponent,
  sdk: EditorSDK,
): Promise<boolean> {
  const allSitePages = await sdk.pages.data.getAll('');
  return !!allSitePages.find(
    (page) => page.tpaPageId === pageId && page.appDefinitionId === ECOM_DEF_ID,
  );
}

async function isEcomInstalled(sdk: EditorSDK): Promise<boolean> {
  return sdk.document.application.isApplicationInstalled(ECOM_DEF_ID, {
    appDefinitionId: ECOM_DEF_ID,
  });
}

type InstallEcomParams = {
  sdk: EditorSDK;
  flowApi: FlowAPI;
};

export async function maybeInstallEcom(params: InstallEcomParams) {
  const {
    flowApi: { httpClient, reportError },
  } = params;

  try {
    const hasFormsWithTransactions =
      (await fetchFormsWithTransactions(httpClient)).length > 0;

    if (hasFormsWithTransactions) {
      await installEcomPages(params);
    }
  } catch (e) {
    reportError(e);
  }
}

export async function installEcomPages({
  sdk,
  flowApi: { reportError },
}: InstallEcomParams) {
  try {
    const ecomCheckoutInstalled = await isEcomPageInstalled(
      EcomComponent.CHECKOUT,
      sdk,
    );
    const ecomThankYouPageInstalled = await isEcomPageInstalled(
      EcomComponent.THANK_YOU_PAGE,
      sdk,
    );

    const ecomInstalled = await isEcomInstalled(sdk);

    if (!ecomInstalled) {
      await installEcomPlatform(sdk);
    }

    if (!ecomCheckoutInstalled) {
      await addComponents(sdk, [EcomComponent.CHECKOUT]);
    }

    if (!ecomThankYouPageInstalled) {
      await addComponents(sdk, [EcomComponent.THANK_YOU_PAGE]);
    }
  } catch (e) {
    reportError(e);
  }
}

async function fetchFormsWithTransactions(httpClient: IHttpClient) {
  try {
    const response = await httpClient?.request(
      queryForms({
        query: {
          filter: {
            'fields.validation.predefined.format': {
              $hasSome: ValidationFormat.PAYMENT,
            },
            namespace: { $eq: NAMESPACE },
          },
          cursorPaging: {
            limit: 1,
          },
        },
      }),
    );
    return response.data.forms;
  } catch (e: any) {
    return [];
  }
}
